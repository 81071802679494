<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as CONTACT_QUALIFICATION_TYPES } from './store'
import { ROUTES as CONTACT_QUALIFICATION_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../../components/thux-list/ThuxListMixin'

import ContactQualificationTable from './ContactQualificationTable'
import ContactQualificationCommonFilters from './ContactQualificationCommonFilters'
import ContactQualificationEdit from './ContactQualificationEdit'

export default {
  name: 'ContactQualificationList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': ContactQualificationTable,
    'common-filters': ContactQualificationCommonFilters,
    'component-edit': ContactQualificationEdit,
    'component-detail': ContactQualificationEdit
  },
  data () {
    return {
      CONTACT_QUALIFICATION_ROUTES,
      title: this.$t('Contact Qualification'),
      rolePerm: ['organization_contactqualification_list'],
      actionEnablePermission: ['organization_contactqualification_enable'],
      actionDisablePermission: ['organization_contactqualification_disable'],
      searchFields: [
        { key: 'filter__code__icontains', placeholder: this.$t('Code'), type: 'text', col: 6 },
        { key: 'filter__name__icontains', placeholder: this.$t('Name'), type: 'text', col: 6 }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ...CONTACT_QUALIFICATION_TYPES.GENERIC.organization.contactqualification.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.CONTACT_QUALIFICATION_ROUTES.CONTACT_QUALIFICATION_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('organization_contactqualification_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: CONTACT_QUALIFICATION_TYPES.GENERIC.organization.contactqualification.LIST.MUTATIONS.setSelectedList,
      setSelectAll: CONTACT_QUALIFICATION_TYPES.GENERIC.organization.contactqualification.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...CONTACT_QUALIFICATION_TYPES.GENERIC.organization.contactqualification.LIST.ACTIONS
    })
  }
}
</script>
